import React, { useState } from "react"
import axios from 'axios'

import Layout from "../components/layout/layout"

const TestPage = () => {

  const [notification, setNotification] = useState('')

  const handleApiTest = () => {
    fetch('/api/test', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      setNotification(data.msg)
    })
  }

  const sendTestEmail = () => {
    // fetch('/api/send', { 
    //   method: 'POST',
    //   body: JSON.stringify({
    //     from: 'Max2 Security',
    //     to: 'justin.spegele@gmail.com',
    //     subject: 'Max2 Test Notification',
    //     html: "<h3>Test Email from Max<sup>2</sup> Security</h3><p><em>This is a test notification</em></p><p>Thank you,<br/>Max<sup>2</sup></p>"
    //   }),
    //   headers: {"Content-Type": "application/json"}
    // })
    // .then(res => res.json())
    // .then(data => {
    //   setNotification('Email status: ' + data.msg)
    // })

    axios({
      method: "POST", 
      url:'/api/send', 
      data: {
        from: 'Max2 Security',
        to: 'justin.spegele@gmail.com',
        subject: 'Max2 Test Notification v2',
        html: "<h3>Test Email from Max<sup>2</sup> Security</h3><p><em>This is a test notification</em></p><p>Thank you,<br/>Max<sup>2</sup></p>"
      }
    }).then(res => {
      setNotification('Email status: ' + res.data.msg)
    })
  }

  return (
    <Layout>
      <h1>Server Test</h1>
      <p>Max2 Security server test page</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <button onClick={handleApiTest}>Test the API</button>
        <button onClick={sendTestEmail}>Send a test email</button>
      </div>
      {notification && <span>{notification}</span>}
    </Layout>
  )
}

export default TestPage
